import { useRef } from "react";
import { Text3D } from "@react-three/drei";
import * as THREE from "three";

export default function FedddevText({ onClick }) {
  const txtRef = useRef();
  const material = new THREE.MeshMatcapMaterial();

  const eventHandler = (event) => {
    txtRef.current.material.color.set(`hsl(${Math.random() * 360}, 100%, 75%)`);
    onClick && onClick(event);
  };

  return (
    <Text3D
      ref={txtRef}
      onClick={eventHandler}
      onPointerEnter={() => {
        document.body.style.cursor = "pointer";
      }}
      onPointerLeave={() => {
        document.body.style.cursor = "default";
      }}
      position={[-2.75, -1, 0]}
      rotation={[0, 0, 0]}
      material={material}
      font="./fonts/helvetiker_regular.typeface.json"
      size={1}
      height={0.2}
      curveSegments={12}
      bevelEnabled
      bevelThickness={0.02}
      bevelSize={0.02}
      bevelOffset={0}
      bevelSegments={5}
    >
      FEDDDEV
      <meshLambertMaterial attach="material" color={"white"} />
    </Text3D>
  );
}
