import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  Typography,
  Box,
} from "@mui/material";
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useForm, Controller } from "react-hook-form";

const ContactUsDialog = ({ open, handleClose }) => {
  const [newInquiryName, setInquiryName] = useState("");
  const [newInquiryBusinessName, setInquiryBusinessName] = useState("");
  const [newInquiryEmailAddress, setInquiryEmailAddress] = useState("");
  const [newInquirySMSPref, setInquirySMSPref] = useState(false);
  const [newInquiryEmailPref, setInquiryEmailPref] = useState(false);
  const [newInquiryPhoneNumber, setInquiryPhoneNumber] = useState("");
  const [newInquiryReasonIsWorkRequest, setInquiryReasonIsWorkRequest] =
    useState(false);
  const [newInquiryReasonIsInquiry, setInquiryReasonIsInquiry] = useState("");
  const [newInquiryHouseSize, setInquiryHouseSize] = useState("");
  const [newInquiryHDPhots, setInquiryHDPhotos] = useState("");
  const [newInquiry3DScan, setInquiry3DScan] = useState(false);
  const [newInquiryMessage, setInquiryMessage] = useState(false);
  const [inquiries, setInquiries] = useState([]);

  const inquiriesCollectionRef = collection(db, "Inquiries");

  const [captchaValue, setCaptchaValue] = useState(null);

  const submitForm = () => {
    recaptchaRef.current.execute();
  };
  const recaptchaRef = useRef(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const createInquiry = async () => {
    await addDoc(inquiriesCollectionRef, {
      inquiryName: newInquiryName,
      inquiryBusinessName: newInquiryBusinessName,
      inquiryEmailAddress: newInquiryEmailAddress,
      inquirySMSPref: newInquirySMSPref,
      inquiryEmailPref: newInquiryEmailPref,
      inquiryPhoneNumber: newInquiryPhoneNumber,
      inquiryReasonIsWorkRequest: newInquiryReasonIsWorkRequest,
      inquiryReasonIsInquiry: newInquiryReasonIsInquiry,
      inquiryHouseSize: newInquiryHouseSize,
      inquiryHDPhotos: newInquiryHDPhots,
      inquiry3DScan: newInquiry3DScan,
      inquiryMessage: newInquiryMessage,
    });
  };

  useEffect(() => {
    const getInquiries = async () => {
      // getDocs returns all of the data from a document in firestore
      const data = await getDocs(inquiriesCollectionRef);
      console.log(data);
      setInquiries(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getInquiries();
  }, []);

  const [submitted, setSubmitted] = useState(false);
  const [isWorkRequestSelected, setIsWorkRequestSelected] = useState(false);
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = (data) => {
    console.log(data);

    setSubmitted(true);
    reset();
    // setTimeout(() => {
    //   handleClose();
    //   setSubmitted(false);
    // }, 5000);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {submitted ? (
        <DialogContent>
          <Box textAlign="center">
            <Typography variant="h6" textAlign="center">
              Your inquiry has been submitted.
            </Typography>
            <Typography>
              We appreciate your interest and will get back to you as soon as
              possible.
            </Typography>
          </Box>
        </DialogContent>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography>
            <DialogTitle>Contact Us</DialogTitle>
            <DialogContent>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Name"
                    fullWidth
                    margin="dense"
                    onChange={(event) => {
                      setInquiryName(event.target.value);
                    }}
                  />
                )}
              />
              <Controller
                name="businessName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Business Name"
                    fullWidth
                    margin="dense"
                    onChange={(event) => {
                      setInquiryBusinessName(event.target.value);
                    }}
                  />
                )}
              />
              <Controller
                name="emailAddress"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email Address"
                    fullWidth
                    margin="dense"
                    onChange={(event) => {
                      setInquiryEmailAddress(event.target.value);
                    }}
                  />
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone Number"
                    fullWidth
                    margin="dense"
                    onChange={(event) => {
                      setInquiryPhoneNumber(event.target.value);
                    }}
                  />
                )}
              />
              <div>
                <FormControl component="fieldset" margin="dense">
                  <label>Preferred Contact Method</label>
                  <FormGroup>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={(event) =>
                                setInquiryEmailPref(event.target.checked)
                              }
                            />
                          }
                          label="E-mail"
                        />
                      )}
                    />
                    <Controller
                      name="sms"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={(event) =>
                                setInquirySMSPref(event.target.checked)
                              }
                            />
                          }
                          label="SMS"
                        />
                      )}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <FormControl component="fieldset" margin="dense">
                <label>Reason for Contact</label>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Controller
                        name="workRequestCheckbox"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            onChange={(e) => {
                              // field.onChange(e.target.checked);
                              setIsWorkRequestSelected(e.target.checked);
                              setInquiryReasonIsWorkRequest(e.target.checked);
                            }}
                          />
                        )}
                      />
                    }
                    label="Work Request"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        name="inquiryCheckbox"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            onChange={(event) =>
                              setInquiryReasonIsInquiry(event.target.checked)
                            }
                          />
                        )}
                      />
                    }
                    label="Inquiry"
                  />
                </FormGroup>
              </FormControl>
              {isWorkRequestSelected && (
                <>
                  <Controller
                    name="houseSize"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="House Size (sq ft)"
                        fullWidth
                        margin="dense"
                        onChange={(event) => {
                          setInquiryHouseSize(event.target.value);
                        }}
                      />
                    )}
                  />
                  <FormControl component="fieldset" margin="dense">
                    <label>Services</label>
                    <FormGroup>
                      <Controller
                        name="hdPhotos"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                onChange={(event) =>
                                  setInquiryHDPhotos(event.target.checked)
                                }
                              />
                            }
                            label="HD Photos"
                          />
                        )}
                      />
                      <Controller
                        name="threeDScan"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                onChange={(event) =>
                                  setInquiry3DScan(event.target.checked)
                                }
                              />
                            }
                            label="3D Scan"
                          />
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                </>
              )}

              <Controller
                name="inquiryText"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Please enter a detailed message"
                    fullWidth
                    multiline
                    rows={4}
                    margin="dense"
                    onChange={(event) => setInquiryMessage(event.target.value)}
                    // placeholder="Ask us anything!"
                  />
                )}
              />
            </DialogContent>
            <Box display="flex" justifyContent="center">
              <ReCAPTCHA
                sitekey="6LeUzRYlAAAAALKVNE1CtOOBOrzm8JllqHUzlHtG"
                ref={recaptchaRef}
                onChange={handleCaptchaChange}
              />
            </Box>
            {/* <ReCAPTCHA
              // daniel's key for fedd.dev using personal email: sitekey="6LdZUBQlAAAAAMHtBE2fXMd1kp9_0y5QaMeQc0wm"
              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={handleCaptchaChange}
            /> */}

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={createInquiry}
                type="submit"
                color="primary"
                disabled={!captchaValue}
              >
                Submit
              </Button>
            </DialogActions>
          </Typography>
        </form>
      )}
    </Dialog>
  );
};
export default ContactUsDialog;
