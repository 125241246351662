import React, { createContext, useState } from "react";

export const ThreeDSceneContext = createContext({
  clicked: false,
  updateClicked: () => {},
});

export const ThreeDSceneProvider = ({ children }) => {
  const [clicked, setClicked] = useState(false);
  const [isCameraAtStartPosition, setIsCameraAtStartPosition] = useState(true);

  const updateClicked = (newClicked) => {
    setClicked(newClicked);
    console.log(newClicked);
  };

  return (
    <ThreeDSceneContext.Provider
      value={{
        clicked,
        updateClicked,
        isCameraAtStartPosition,
        setIsCameraAtStartPosition,
      }}
    >
      {children}
    </ThreeDSceneContext.Provider>
  );
};
