import React, { useState } from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import ContactUsDialog from "./ContactUsDialog";
import AboutDialog from "./AboutDialog";
import ServicesDialog from "./ServicesDialog";
import LoginRegisterDialog from "./LoginRegisterDialog"; // Import the new component

// import oldLoginRegisterDialog from "./oldLoginRegisterDialog"; // Import the new component

const CustomAppBar = () => {
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [loginRegisterOpen, setLoginRegisterOpen] = useState(false); // Add state for login/registration dialog
  const handleWorkRequestClick = () => {
    setServicesOpen(false);
    setContactUsOpen(true);
  };
  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        style={{ background: "transparent" }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" onClick={() => setAboutOpen(true)}>
            About
          </Button>
          <Button color="inherit" onClick={() => setServicesOpen(true)}>
            Services
          </Button>
          <Button color="inherit" onClick={() => setContactUsOpen(true)}>
            Contact Us
          </Button>
          {/* <Button color="inherit" onClick={() => setLoginRegisterOpen(true)}> */}
            {/* {" "} */}
            {/* Add Login/Register button */}
            {/* Log In */}
          {/* </Button> */}
        </Toolbar>
      </AppBar>
      <AboutDialog open={aboutOpen} handleClose={() => setAboutOpen(false)} />
      <ServicesDialog
        open={servicesOpen}
        handleClose={() => setServicesOpen(false)}
        handleButtonClick={handleWorkRequestClick}
      />
      <ContactUsDialog
        open={contactUsOpen}
        handleClose={() => setContactUsOpen(false)}
      />
      <LoginRegisterDialog
        open={loginRegisterOpen}
        handleClose={() => setLoginRegisterOpen(false)} // Add the new dialog
      />
    </>
  );
};

export default CustomAppBar;
