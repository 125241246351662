import { useGLTF } from "@react-three/drei";
import { MathUtils } from "three";
export default function HouseModel() {
  const house_model = useGLTF("./modern_house.glb");
  console.log("model: ", house_model);
  return (
    <primitive
      object={house_model.scene}
      scale={0.3}
      position={[4, 0, 0]}
      rotation={[0, MathUtils.degToRad(90), 0]}
    />
  );
}
