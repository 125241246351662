import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase-config";

const LoginRegisterDialog = ({ open, handleClose }) => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!captchaValue) return;
    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      handleClose();
      //   setLoginSuccess(true);
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!captchaValue) return;
    try {
      await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      handleClose();
    } catch (error) {
      console.error("Error registering:", error);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        {currentUser ? (
          <>
            <Typography variant="h6" mb={2}>
              You are currently logged in as: {currentUser.email}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mr: 6 }}>
              <Button color="primary" onClick={handleLogout}>
                Log Out
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box component="form" mb={3} onSubmit={handleLogin}>
              <Typography variant="h6" ml={1}>
                Log In
              </Typography>
              <TextField
                fullWidth
                label="Email"
                type="email"
                margin="normal"
                variant="outlined"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                margin="normal"
                variant="outlined"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 1 }}>
                <Button color="primary" type="submit" disabled={!captchaValue}>
                  Login
                </Button>
              </Box>
            </Box>
            <Box component="form" onSubmit={handleRegister}>
              <Typography variant="h6" ml={1}>
                Register
              </Typography>
              <TextField
                fullWidth
                label="Email"
                type="email"
                margin="normal"
                variant="outlined"
                value={registerEmail}
                onChange={(e) => setRegisterEmail(e.target.value)}
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                margin="normal"
                variant="outlined"
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 1 }}>
                <Button color="primary" type="submit" disabled={!captchaValue}>
                  Register
                </Button>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <ReCAPTCHA
                  sitekey="6LdZUBQlAAAAAMHtBE2fXMd1kp9_0y5QaMeQc0wm"
                  ref={recaptchaRef}
                  onChange={handleCaptchaChange}
                />
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default LoginRegisterDialog;
