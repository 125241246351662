import React from "react";
import { Typography, Box, Button } from "@mui/material";

const Services = ({ handleButtonClick }) => {
  const pricingData = [
    {
      title: "Full Photo Shoots",
      prices: [
        { size: "Under 1,000 SF", price: 195 },
        { size: "1,000 to 2,499 SF", price: 225 },
        { size: "2,500 to 3,999 SF", price: 255 },
        { size: "4,000 to 5,000 SF", price: 295 },
        { size: "Over 5,000 SF", custom: true },
      ],
    },
    {
      title: "Photos + Matterport",
      prices: [
        { size: "Under 1,000 SF", price: 390 },
        { size: "1,000 to 2,499 SF", price: 490 },
        { size: "2,500 to 3,999 SF", price: 590 },
        { size: "4,000 to 5,000 SF", price: 690 },
        { size: "Over 5,000 SF", custom: true },
      ],
    },
  ];
  return (
    <Box textAlign="center" mt={3} mb={4}>
      {" "}
      {/* Add textAlign="center" to center the text */}
      <Typography variant="h5">Services and Pricing</Typography>
      {pricingData.map((section, index) => (
        <Box key={index} mt={4}>
          <Typography variant="h6">{section.title}</Typography>
          <Box mt={1}>
            {section.prices.map((priceInfo, idx) => (
              <Typography key={idx} variant="body1">
                {priceInfo.custom
                  ? `${priceInfo.size} - Custom Quote`
                  : `${priceInfo.price} - ${priceInfo.size}`}
              </Typography>
            ))}
          </Box>
        </Box>
      ))}
      <Box mt={3}>
        <Button color="primary" onClick={handleButtonClick}>
          Submit a Work Request
        </Button>
      </Box>
    </Box>
  );
};

export default Services;
