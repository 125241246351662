import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCo_yxX8r-x06lt_9oW5YzPEOle6RO01Io",
  authDomain: "feddema-development.firebaseapp.com",
  projectId: "feddema-development",
  storageBucket: "feddema-development.appspot.com",
  messagingSenderId: "1094565674780",
  appId: "1:1094565674780:web:94c27ec5405fc7e8b8c4d9",
  measurementId: "G-ZFEQPQTBJV",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore();
