import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

const AboutDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box mt={3} mb={1} ml={4} mr={4}>
        <DialogTitle
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h5" component="div">
            Feddema Development
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: (theme) => theme.spacing(1),
          }}
        >
          <Typography variant="body1" paragraph>
            FeddDev is a 3D technologies company taking a grass-roots approach
            to metaverse adoption, creating digital neighborhoods one scan at a
            time. We provide photography and 3D scanning services via
            Matterport's Pro2 HD, 3D platform in anticipation of our pipeline
            under development to convert the scans into web-available assets
            capable of game-like interaction.
          </Typography>
        </DialogContent>
        <Box mt={3}>
          {/* <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions> */}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AboutDialog;
