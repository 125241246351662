import {
  Center,
  Text3D,
  OrbitControls,
  useMatcapTexture,
  useGLTF,
} from "@react-three/drei";
import HouseModel from "./HouseModel.js";
import * as THREE from "three";
import { useContext, useEffect, useState } from "react";
import { ThreeDSceneContext } from "../context/ThreeDSceneContext.js";
import FedddevText from "./FedddevText";
import { useThree } from "@react-three/fiber";

const material = new THREE.MeshMatcapMaterial();

export default function Experience() {
  const house_model = useGLTF("./modern_house.glb");
  console.log(house_model);
  const [matcapTexture] = useMatcapTexture("54C104_BBFA0F_97EF04_7AE104", 256);
  const { clicked, updateClicked } = useContext(ThreeDSceneContext);
  const camera = useThree((state) => state.camera);

  const [originalCameraPosition, setOriginalCameraPosition] = useState();

  useEffect(() => {
    if (camera) {
      setOriginalCameraPosition(camera.position.clone());
    }
  }, [camera]);

  const handleTextClick = () => {
    if (camera && originalCameraPosition) {
      const duration = 1.5; // duration of the animation in seconds
      const startPosition = camera.position.clone();
      const endPosition = originalCameraPosition.clone();
      let currentTime = 0;

      const animateCamera = () => {
        currentTime += 1 / 60; // increment the current time by the time elapsed since the last frame
        const t = Math.min(currentTime / duration, 1); // calculate the current time as a percentage of the total duration (capped at 1)

        camera.position.lerpVectors(startPosition, endPosition, t); // linear interpolation between the start and end positions based on the current time
        camera.lookAt(0, 0, 0);
        camera.updateProjectionMatrix();

        if (currentTime < duration) {
          requestAnimationFrame(animateCamera); // request the next frame
        }
      };

      animateCamera();
    }
  };

  return (
    <group position={[0, -2, 0]}>
      <HouseModel />
      <FedddevText onClick={handleTextClick} />
    </group>
  );
}
