import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import Services from "./Services";

const ServicesDialog = ({ open, handleClose, handleButtonClick }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Services handleButtonClick={handleButtonClick} />
      </DialogContent>
    </Dialog>
  );
};

export default ServicesDialog;
