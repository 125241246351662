import React from "react";
import { Canvas } from "@react-three/fiber";
import Experience from "./components/Experience.js";
import { OrbitControls } from "@react-three/drei";
import { MathUtils } from "three";
import { ThreeDSceneProvider } from "./context/ThreeDSceneContext.js";
import { Container } from "@mui/material";
import CustomAppBar from "./components/CustomAppBar"; // Import CustomAppBar
import "./style.css";

function App() {
  console.log("App has been rendered");
  return (
    <ThreeDSceneProvider>
      <CustomAppBar />
      <Canvas shadows camera={{ position: [0, 2, 8] }}>
        <OrbitControls
          enablePan={false}
          // enableZoom={true}
          makeDefault
          maxDistance={16}
          minPolarAngle={MathUtils.degToRad(45)}
          maxPolarAngle={MathUtils.degToRad(90)}
          minAzimuthAngle={MathUtils.degToRad(-70)}
          maxAzimuthAngle={MathUtils.degToRad(70)}
        />
        <pointLight position={[-5, 14, 10]} />
        <Experience />
      </Canvas>
    </ThreeDSceneProvider>
  );
}

export default App;
